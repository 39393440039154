import axios from 'axios'
import {Message } from 'element-ui'
// import store from '@/store'
import { getToken } from '@/utils/auth'
// import errorCode from '@/utils/errorCode'


axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const request = axios.create({
  baseURL: process.env.VUE_APP_URL,
  timeout: 30000
})
// request拦截器
request.interceptors.request.use((config) => {
  if (getToken()) {
      config.headers['Authorization'] = getToken() 
    }
    return config
  },(error) => {
    // 失败执行promise
    return Promise.reject(error)
  }),

// 响应拦截器
request.interceptors.response.use((response) => {
  // console.log(response.data)
  const { data, msg, code } = response.data // 默认json格式
  if (code == 0) {
    // console.log('请求成功')
    return data
  } else if (code == 401) {
    Message({ type: 'error', message: '请重新登录' })
    setTimeout(() => {
      localStorage.clear()
    }, 1000)
  } else {
    Message({ type: 'error', message: msg })
    return Promise.reject(new Error(msg))
  }
}, (error) => {
  console.log('请求失败')
  console.dir(error)
  return Promise.reject(error)
})
export default request
