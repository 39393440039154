import request from '../utils/request'
// import * as config from "../utils/config";
// import store from '../store'
// console.log('store :>> ', store);
// const IP = config.environment === 'TE' ? 'https://csiva.terabits.cn:9091' : 'https://zjiva.terabits.cn:9091/'
// const Authorization = store.getters.token
 
export function labpass () {
  return request({
    url:`/lab/bigScreen/passPersonNum`,
    method: 'get',
  })
  
}
export function introduce () {
  return request({
    url:`/lab/bigScreen/getIntroduce`,
    method: 'get',
  })
  
}

export function energyUser (params) {
  return request({
    url:`/lab/bigScreen/currentDayEnergyUseConsumption`,
    method: 'get',
    params:{
      startTime:params[0],
      endTime:params[0],
    }
  })
  
}

export function getClass (params) {
  return request({
    url:`/lab/bigScreen/currentDayLabCourse`,
    method: 'get',
    params:{
      startTime:params[0],
      endTime:params[0],
    }
  })
  
}
export function getlabEnv () {
  return request({
    url:`/lab/bigScreen/labEnv`,
    method: 'get',
  })
  
}
export function getProject () {
  return request({
    url:`/lab/bigScreen/getProjectInfo`,
    method: 'get',
  })
  
}
export function getShowWay () {
  return request({
    url:`/lab/bigScreen/getCenterConfig`,
    method: 'get',
  })
  
}
