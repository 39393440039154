<template>
  <div  id="mycard" class="container" @mouseEnter="mouserEneter" @mouseLeave="mouserLeave">
    <div class="card">
      <div class="front_Card">
        <div class="title">
          课程安排
        </div>
        <div class="headLine">
          <el-row class="caption">
           <el-col :span="7" :offset="1">时间</el-col>
           <el-col :span="8">课程</el-col>
           <el-col :span="8">实验室</el-col>
          </el-row>
        </div>
        <div class="swiper swiper_city">
         <div class="swiper-wrapper">
         <el-row class="swiper-slide" v-for="item in classList" :key="item.id" >
           <el-col :span="7" :offset="1">{{`${$moment(item.startTime).format('HH:mm')}-${$moment(item.endTime).format('HH:mm')}`}}</el-col>
           <el-col :span="8">{{ item.name }}</el-col>
           <el-col :span="8">{{ item.labName }}</el-col>
         </el-row>
         </div>
        </div>
      </div>
      <div class="back_Card">
        <div class="title">
          项目信息
        </div>
        <div class="headLine">
          <el-row class="caption">
           <el-col :span="11" :offset="1">项目名称</el-col>
           <el-col :span="6">类型</el-col>
           <el-col :span="6">负责人</el-col>
          </el-row>
        </div>
        <div class="swiper swiper_Pr">
         <div class="swiper-wrapper">
         <el-row class="swiper-slide" v-for="item in projectList" :key="item.id" >
           <el-col :span="11" :offset="1" style="word-break: break-all;">{{item.name}}</el-col>
           <el-col :span="6">{{ item.type }}</el-col>
           <el-col :span="6">{{ item.dutyPerson }}</el-col>
         </el-row>
         </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getClass ,getProject} from "@/api";
import Swiper, { Autoplay } from 'swiper';
import 'swiper/swiper.scss';
Swiper.use([Autoplay])
export default{
  data(){
    return{
      timeID: null,
      reqTimer:null,
      classList:[],
      projectList:[]
    }
  },
  created(){
    this.getClass()
    this.getPr()
  },
  mounted(){
    this.timeID = setInterval(() => {
      document.querySelector("#mycard").classList.toggle("flip");
    }, 10000)
    this.reqTimer=setInterval(() => {
      this.getClass()
      this.getPr()
    }, 7200000)
  },
  destroyed(){
    clearInterval(this.timeID)
  },
  methods:{
    mouserEneter() {
      document.querySelector("#mycard").classList.remove("flip");
      if (this.timeID) {
          clearInterval(this.timeID);
      }
    },
   mouserLeave() {
     this.timeID = setInterval(() => {
         document.querySelector("#mycard").classList.toggle("flip");
     }, 3000) },

  async getClass(){
    const res =  await getClass([null,null])
    this.classList= res
    this.initSwiper()
    },
    initSwiper () {
      this.$nextTick(() => {
        if (this.classList && this.classList.length > 5) {
          new Swiper('.swiper_city', {
            autoplay: {
              delay: 0,
              stopOnLastSlide: false,
              disableOnInteraction: false,
              pauseOnMouseEnter: true
            },
            direction: 'vertical',
            slidesPerView: 'auto',
            loop: true,
            speed: 1500
          })
        }
        if (this.classList && this.classList.length <= 5) {
          new Swiper('.swiper_city', {
            direction: 'vertical',
            slidesPerView: 'auto',
            // loop: true,
            speed: 1500
          })
        }
      })
    },
    async getPr(){
      const res = await getProject()
      this.projectList= res.content
      this.initPrSwiper()
    },
    initPrSwiper () {
      this.$nextTick(() => {
        if (this.projectList && this.projectList.length > 5) {
          new Swiper('.swiper_Pr', {
            autoplay: {
              delay: 0,
              stopOnLastSlide: false,
              disableOnInteraction: false,
              pauseOnMouseEnter: true
            },
            direction: 'vertical',
            slidesPerView: 'auto',
            loop: true,
            speed: 1500
          })
        }
        if (this.projectList && this.projectList.length <= 5) {
          new Swiper('.swiper_Pr', {
            direction: 'vertical',
            slidesPerView: 'auto',
            // loop: true,
            speed: 1500
          })
        }
      })
    },
  }
}

</script>
<style lang="scss" scoped>
*{
  box-sizing: border-box;
}
 .container {
     perspective: 1000; /* 其子元素会获得透视效果，而不是元素本身。 */
     color: azure;
     
  }

  /* 手动控制翻转 */
  .container.flip .card {
      transform: rotateY(180deg);
  }

  .container,
  .front_Card,
  .back_Card {
      width: 422px;
      height: 361px;
  }

  .card {
      transition: 0.6s;
      transform-style: preserve-3d; /* 让其有3d效果 */
      position: relative;
  }

  .front_Card,
  .back_Card {
      backface-visibility: hidden; /*翻转隐藏背面 */
      position: absolute;
      top: 0;
      left: 0;
      // width: 100%;
      padding: 0 20px;
      .title{
      display: block;
      font-size: 22px;
      font-family: SourceHanSansCN;
      font-weight: 400;
      color: #FFFFFF;
      padding: 15px 0;
      padding-left: 40px;
      margin-bottom: 15px;
     }
     .headLine{
       font-size: 16px;
       font-weight: 400;
       color: #00E5ED;
      }
     .swiper{
      font-size: 16px;
      font-weight: 400;
      color: #FFFFFF;
      
     } 
     .swiper_city, .swiper_Pr {
    // width: 300px;
    font-size: 16px;
    font-weight: 400;
    color: #FFFFFF;
    // overflow: hidden;
    height:230px;
    margin-top: 15px;
    .swiper-wrapper{
      flex-direction: column;
      .swiper-slide {
      height: 45px;
      // padding-top: 10px;
      color: white;
      display: flex;
      align-items: center;
      font-size: 14px;
      .el-col {
        // line-height: 45px;
        // overflow: hidden;
        text-overflow: ellipsis;
        white-space:break-spaces;
        // padding: 15px 0;
      }
     }
     .swiper-slide:nth-child(2n) {
      background:linear-gradient(90deg, #153c67 0%, #0a2b57 100%);
     }
    }
   }
  }

  .front_Card {
    z-index: 2;
    background: url('~@/assets/lab/leftbottom.png') no-repeat center center;
    background-size: 100% 100%;
  }

  .back_Card {
    background: url('~@/assets/lab/leftbottom.png') no-repeat center center;
    background-size: 100% 100%;
    transform: rotateY(180deg);
  }

  // .back div {
  //     width: 100%;
  // }
  </style>