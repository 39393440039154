<template>
  <div class="labBottom">
    <card />
    <div class="mid">
      <div class="mainBox">
        <el-carousel indicator-position="none"  direction="horizontal" :interval="10000">
         <el-carousel-item v-for="(item,index) in envList" :key="item.labId">
            <div class="labelBox">
              <div class="labelBox_left">
                <span class="text"> {{ index===0?envList[envList.length-1].labName:envList[index-1].labName }}</span>
              </div>
              <div class="labelBox_cen">{{ item.labName  }}</div>
              <div class="labelBox_right">
                <span class="text">
                  {{ index===envList.length-1?envList[0].labName:envList[index+1].labName }}
                </span>
              </div>

            </div>
            <div class="cardBox">
              <div class="card" >
                <div class="img"><img src="@/assets/lab/tem.png" alt=""></div>
                <div class="text">
                  <div class="num">{{item.env[0]?item.env[0].temp.last:'-'}} <span>(℃)</span></div>
                  <div class="type">温度</div>
                </div>
              </div>
              <div class="card" >
                <div class="img"><img src="@/assets/lab/wet.png" alt=""></div>
                <div class="text">
                  <div class="num">{{item.env[0]?item.env[0].humidity.last:'-'}} <span>(%Rh)</span></div>
                  <div class="type">湿度</div>
                </div>
              </div>
              <div class="card" >
                <div class="img"><img src="@/assets/lab/light.png" alt=""></div>
                <div class="text">
                  <div class="num">{{item.env[0]?item.env[0].lightLevel.last:'-'}} <span>(级)</span></div>
                  <div class="type">光照等级</div>
                </div>
              </div>
              <div class="card" >
                <div class="img"><img src="@/assets/lab/air.png" alt=""></div>
                <div class="text">
                  <div class="num">{{item.env[0]?item.env[0].pressure.last:'-'}} <span>(hPa)</span></div>
                  <div class="type">大气压</div>
                </div>
              </div>
              <div class="card" >
                <div class="img"><img src="@/assets/lab/co2.png" alt=""></div>
                <div class="text">
                  <div class="num">{{item.env[0]?item.env[0].co2.last:'-'}} <span>(ppm)</span></div>
                  <div class="type">co2</div>
                </div>
              </div>
              <div class="card" >
                <div class="img"><img src="@/assets/lab/tvoc.png" alt=""></div>
                <div class="text">
                  <div class="num">{{item.env[0]?item.env[0].tvoc.last:'-'}} <span>(mg/m³)</span></div>
                  <div class="type">tvoc</div>
                </div>
              </div>            
            </div>
         </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="right">
      <div class="passBox">
        <div class="passCard">
          <div><span>{{ passList[0].passNum}}</span>(人次)</div>
          <div>{{ passList[0].labName }}</div>
        </div>
        <div class="passCardBox">
          <div class="passCard">
            <div><span>{{ passList[1].passNum}}</span>(人次)</div>
            <div>{{ passList[1].labName }}</div>
          </div>
          <div class="passCard">
            <div><span>{{ passList[2].passNum}}</span>(人次)</div>
            <div>{{ passList[2].labName }}</div>
          </div>
          <div class="passCard">
            <div><span>{{ passList[3].passNum}}</span>(人次)</div>
            <div>{{ passList[3].labName }}</div>
          </div>
          <div class="passCard">
            <div><span>{{ passList[4].passNum}}</span>(人次)</div>
            <div>{{ passList[4].labName }}</div>
          </div>
        </div>
        
        <div class="passTotal">
          <div class="totalPass">
            {{ passTotal }}
            <div>
            近半年通行总数
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getlabEnv,labpass} from "@/api";
import card from './card.vue';
export default {
  components:{card},
  data () {
    return {
      envList:[],
      passTotal:'',
      passList:[],
      envTimer:null,
      passTimer:null,
    }
  },
  computed:{

  },
  created(){
    this.getenv()
    this.getpassNum()
  },
  mounted(){
    // this.envTimer = setInterval(() => {
    // this.getenv();
    // }, 60000); // 一分钟触发一次getenv函数
    // this.passTimer = setInterval(() => {
    // this.getpassNum();
    // }, 10000)
  },
  destroyed(){
    clearInterval(this.envTimer,this.passTimer);
  },
  methods:{
   async getenv(){
    const res =await getlabEnv()
    this.envList=res
   },
   async getpassNum(){
    const res = await labpass()
    this.passTotal=res.passNum
    this.passList=res.passRecord
    },

  }
}

</script>
<style lang="scss" scoped>
*{
  box-sizing: border-box;
}
.labBottom{
  width: 100%;
  height: 361px;
  padding: 0 20px;
  display: flex;
  font-family: SourceHanSansCN;
  .card{
    width: 422px;
  }
  .left{
    width: 20%;
    height: 361px;
    background: url('~@/assets/lab/leftbottom.png') no-repeat center center;
    background-size: 100% 100%;
    padding: 0 20px;
    .title{
      display: block;
      font-size: 22px;
      font-family: SourceHanSansCN;
      font-weight: 400;
      color: #FFFFFF;
      padding: 15px 0;
      padding-left: 40px;
      margin-bottom: 15px;
    }
   .headLine{
    font-size: 16px;
    font-weight: 400;
    color: #00E5ED;
   }
   .swiper{
    font-size: 16px;
    font-weight: 400;
    color: #FFFFFF;
    
   }
  .swiper_city {
    // width: 300px;
    font-size: 16px;
    font-weight: 400;
    color: #FFFFFF;
    // overflow: hidden;
    height:230px;
    margin-top: 15px;
    .swiper-wrapper{
      flex-direction: column;
      .swiper-slide {
      height: 45px;
      // padding-top: 10px;
      color: white;
      display: flex;
      align-items: center;
      font-size: 14px;
      .el-col {
        line-height: 45px;
        // overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        // padding: 15px 0;
      }
     }
     .swiper-slide:nth-child(2n) {
      background:linear-gradient(90deg, #153c67 0%, #0a2b57 100%);
     }
    }
   
     
}
  }
  .mid{
    flex: 1;
    margin: 0 20px;
    background: url('~@/assets/lab/bottom.png') no-repeat center center;
    background-size: 100% 100%;
  //   &::before{
  //   content: '环境指标';
  //   display: inline-block;
  //   font-size: 22px;
  //   font-family: SourceHanSansCN;
  //   font-weight: 400;
  //   color: #FFFFFF;
  //   padding: 15px 0;
  //   padding-left: 60px;
  //   position: fixed;
  //   z-index: 10;
  //   // margin-bottom: 15px;
  // }
  .mainBox{
    width: 100%;
    padding: 0 50px;
    // padding: 0 5=px;
   ::v-deep .el-carousel__container{
      height: 361px;
      .el-carousel__item {
        // display: flex;
        // justify-content: space-evenly;
        // flex-wrap: wrap;
      }
    }
    .labelBox{
     
      display: flex;
      justify-content: center;
      color: #fff;
      line-height: 43px;
      font-size: 16px;
      font-weight: 400;
      text-align: center;
      width: 100%;
      padding: 7px 0;
      margin-bottom:23px;
      position: relative;
      &::before{
    content: '环境指标';
    // display: inline-block;
    font-size: 22px;
    font-family: SourceHanSansCN;
    font-weight: 400;
    color: #FFFFFF;
    padding-left: 30px;
    position: absolute;
    left: 0;
    // top:50%;
    // transform: translateY(-50%);
    // z-index: 10;bottomMidTiLeft
    // margin-bottom: 15px;
  }
  .labelBox_left{
    background: url('~@/assets/lab/bottomMidTiLeft.png') no-repeat ;
    width:150px;
    height:43px;
    background-size: 100% 100%;
    margin: 0 5px;
    .text{
      background: linear-gradient(to right, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255,0.9) 100%);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
    }
  }
  .labelBox_right{
    background: url('~@/assets/lab/bottomMidTit.png') no-repeat ;
    width:150px;
    height:43px;
    background-size: 100% 100%;
    margin: 0 5px;
    .text{
      background: linear-gradient(to left, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255,0.9) 100%);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
    }
  }
  .labelBox_cen{
    background: url('~@/assets/lab/env.png') no-repeat ;
    width:170px;
    height:43px;
    background-size: 100% 100%;
  }
    }
    .cardBox{
        // width: 1000px;
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
      }
  
    .card{
      width: 284px;
      height: 134px;
      background: url('~@/assets/lab/envborder.png') no-repeat center center;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      padding: 25px 0 20px 50px;
      .img{
        width: 41px;
        height: 74px;
        margin-right: 35px;
        img{
          height: 100%;
        }
      }
      .text{
        font-size: 28px;
        font-weight: bold;
        color: #00E5ED;
        line-height: 43px;
        span{
          font-size: 16px;
          margin-left: 10px;
        }
        .type{
          font-size: 16px;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 31px;
        }
      }
    }
  }

    
  }
  .right{
    width: 422px;
    height: 361px;
    background: url('~@/assets/lab/leftbottom.png') no-repeat center center;
    background-size: 100% 100%;
    &::before{
    content: '使用统计';
    display: block;
    font-size: 22px;
    font-family: SourceHanSansCN;
    font-weight: 400;
    color: #FFFFFF;
    padding: 15px 0;
    padding-left: 60px;
    // margin-bottom: 15px;
    }
   
    .passBox{
      padding: 8px 15px;
      width: 100%;
      height:300px;
      position: relative;
      .passCard{
        background: url('~@/assets/lab/uselab.png') no-repeat center center;
        background-size: 100% 100%;
        width: 140px;
        height: 70px;
        margin: 0 auto;
        div:first-child{
            text-align: center;
            font-size: 22px;
            font-weight: bold;
            color: #00E5ED;
            line-height: 43px;
            span{
              font-size: 28px;
            }
          }
          div:last-child{
            text-align: center;
            font-size: 16px;
            color: #fff;
          }
      }
      .passCardBox{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: space-evenly;
        .passCard{
          // margin-top:20px;
          background: url('~@/assets/lab/uselab.png') no-repeat center center;
          background-size: 100% 100%;
          width: 140px;
          height: 70px;
          margin: 20px 0 0 0;
          &:last-child{
          margin: 20px 5px 0 0;
          }
          &:nth-child(3){
          margin: 20px 0 0 5px;
          }
          div:first-child{
            text-align: center;
            font-size: 22px;
            font-weight: bold;
            color: #00E5ED;
            line-height: 43px;
            span{
              font-size: 28px;
            }
          }
          div:last-child{
            text-align: center;
            font-size: 16px;
            color: #fff;
          }
        }
      }
      .passTotal{
        position: absolute;
        background: url('~@/assets/lab/useNum.png') no-repeat center center;
        background-size: 100% 100%;
        width: 138px;
        height: 142px;
        top: 0;
        left: 50%;
        transform: translate(-50%, 50%);
        color: #fff;
        text-align: center;
        .totalPass{
          position: absolute;
          z-index: 55;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 28px;
          font-family: SourceHanSansCN;
          font-weight: bold;
          color: #00E5ED;
          line-height: 43px;
          background: linear-gradient(0deg, #00BDEB 0%, #00FFFF 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          div{
            font-size: 16px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 24px;
          }
         }
      }
    }
  }

}
</style>