<template>
  <div class="mid">
    <div class="midLeft">
      <div class="img">
        <img :src="img" alt="">
      </div>
      <div class="text">
        {{ introduce }}
      </div>
    </div>
    <div class="midcenter" v-if="type===3">
      <el-carousel indicator-position="none"  direction="horizontal" :interval="rotationDuration">
        <el-carousel-item v-for="item in midUrl" :key="item">
          <div class="iframeBox">
           <iframe style="width: 100%" :src="item" frameborder="0" scrolling="no" ></iframe>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="midcenter" v-else>
      <div class="iframeBox">
          <iframe style="width: 100%" :src="midUrl[type-1]" frameborder="0" scrolling="no" ></iframe>
      </div>
    </div>
    <div class="midRight">
      <div class="todayEnergy">今日总能耗：<span>{{ total }} (kW·h)</span></div>
      <div class="pia">
        <div class="chart" ref="chart"></div>
      </div>
      <div class="table">
        <el-row >
          <el-col :span="10"><div></div></el-col>
          <el-col :span="6" :offset="1"><div>今日</div></el-col>
          <el-col :span="6" ><div>昨日</div></el-col>
        </el-row>
        <el-row v-for="(item,index) in energyList" :key="item.labId">
          <el-col :span="10"><div>{{item.labName}}</div></el-col>
          <el-col :span="6" :offset="1"><div :style="{'color':color[index]}">{{ item.currentDayEnergyUse }} <span>kW·h</span></div></el-col>
          <el-col :span="6" ><div>{{ item.yesterdayEnergyUse }} <span>kW·h</span></div></el-col>
        </el-row>
      </div>
    </div>

  </div>
</template>
<script>
import {getPie3D, } from '@/utils/chart'
import { introduce ,energyUser,getShowWay} from "@/api";

const color = ['#33FF9B', '#F8ED41','#0491FA','#9182ff','#ea8450']

export default{
  data(){
    return{
      type:3,
      rotationDuration:10000,
      showWay:{},
      color:['#33FF9B', '#F8ED41','#0491FA','#9182ff','#ea8450'],
      optionData: [],
      statusChart:null,
      option:{},
      img:null,
      introduce:null,
      total:null,
      energyList:[],
      chartTimer:null,
      midUrl:['http://121.40.140.207:10086/scene.html?tag=scenes/zhijiangjiaoshi%202.json&org_id=1&allCacheTime=1704880635570',
           'http://121.40.140.207:10086/scene.html?tag=scenes/IndV%20Nut.json&org_id=1&allCacheTime=1704425218823']
    }
  },
  watch:{
    energyList:{
      handler(newVal, oldVal) {
      if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          const that = this;
          that.setLabel();
          that.initChart();
          window.onresize = function() {
          that.changeSize();
          };
      }
    },
    deep: true
    }
  },
  created(){
    this.getBaseinfo()
    this.getenergyUse()
    this.getShowWay()
  },
  mounted(){
    this.chartTimer=setInterval(() => {
      this.getBaseinfo()
      this.getenergyUse()
      this.getShowWay()
    }, 600000)

  },
  destroyed(){
    clearInterval(this.chartTimer)
  },
  methods:{
    // 学院信息
   async getBaseinfo(){
    const res = await introduce()
    // console.log(res);
    this.img=res.content.pic
    this.introduce=res.content.description
   },
  //  轮播方式
   async getShowWay(){
    const res =await getShowWay()
    this.showWay=res.content
    this.rotationDuration=res.content.rotationDuration*1000
    this.type=res.content.type
   },
  //  能耗信息
  async getenergyUse(){
    const res = await energyUser([null,null])
    // console.log(res);
    this.total=res.totalEnergyUse
    this.optionData=res.energyUseItems.map(i=>{
      return{
        name:i.labName,
        value:i.currentDayEnergyUse
      }
    })
    this.energyList=res.energyUseItems
  
  },
    // echarts
    setLabel () {
      this.optionData.forEach((item, index) => {
        item.itemStyle = {
          color: color[index]
        }
        item.label = {
          normal: {
            show:item.value==0?false: true,
            color: color[index],
            formatter: [
              '{d|{d}%}'
            ].join('\n'), // 用\n来换行
            rich: {
              d: {
                color: color[index],
                align: 'left',
              }
            }
          }
        }
        item.labelLine = {
          normal: {
            length: 10,
            lineStyle: {
              width: 1,
              height:10,
              color: 'rgba(255,255,255,0.7)'
            }
          }
        }
      })
    },
    // 图表初始化
    initChart () {
      this.statusChart = this.$echarts.init(this.$refs.chart)
      // 传入数据生成 option, 构建3d饼状图, 参数工具文件已经备注的很详细
      this.option = getPie3D(this.optionData, 0, 240, 47, 0.08, 0.5)
      this.statusChart.setOption(this.option)
      // 是否需要label指引线，如果要就添加一个透明的2d饼状图并调整角度使得labelLine和3d的饼状图对齐，并再次setOption
      this.option.series.push({
        name: '能耗统计', 
        backgroundColor: 'transparent',
        type: 'pie',
        label: {
          opacity: 1,
          fontSize: 13,
          lineHeight: 20
        },
        startAngle: -40, // 起始角度，支持范围[0, 360]。
        clockwise: false, // 饼图的扇区是否是顺时针排布。上述这两项配置主要是为了对齐3d的样式
        radius: ['20%', '50%'],
        center: ['50%', '50%'],
        data: this.optionData,
        itemStyle: {
          opacity: 0  //这里必须是0，不然2d的图会覆盖在表面
        }
      })
      this.statusChart.setOption(this.option)
      // this.bindListen(this.statusChart)
    },
    changeSize () {
      this.statusChart.resize()
    }
  },
}
</script>
<style lang="scss" scoped>
*{
  box-sizing: border-box;
}
.mid{
width: 100%;
padding:0 20px;
display: flex;
height:576px;
justify-content: space-between;
align-items:center;
.midLeft{
  background: url('~@/assets/lab/lefttop.png') no-repeat center center ;
  width: 422px;
  height:576px;
  background-size: 100% 100%;
  padding: 0 30px 30px 30px;
  &::before{
    content: '学院介绍';
    display: block;
    font-size: 22px;
    font-family: SourceHanSansCN;
    font-weight: 400;
    color: #FFFFFF;
    padding: 15px 0;
    padding-left: 30px;
    margin-bottom: 15px;
  }
  .img{
    background: url('~@/assets/lab/labimgborder.png') no-repeat;
    width: 100%;
    height: 260px;
    background-size: 100% 100%;
    margin-bottom: 30px;
    padding: 15px;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .text{
    font-size: 16px;
    font-family: SourceHanSansCN;
    font-weight: 400;
    color: #FFFFFF;
   }
}
.midcenter{
  flex: 1;
  height:576px;
  background: url('~@/assets/lab/mainbg.png') no-repeat center center ;
  background-size: 100% 100%;
  margin: 0 20px;
  padding: 20px;
  overflow: hidden;
  &::-webkit-scrollbar{
        display: none;
      }

    ::v-deep .el-carousel__container{
      height: 536px;
    }
  .iframeBox{
    width: 100%;
    height: 536px;
    overflow: hidden;
    position: relative;
    &::-webkit-scrollbar{
        display: none;
      }
    iframe{
      height: 600px;
      position: absolute;
      bottom: 0;
      &::-webkit-scrollbar{
        display: none;
      }
    }
  }
}
.midRight{
  background: url('~@/assets/lab/lefttop.png') no-repeat center center ;
  width: 422px;
  height:576px;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  padding: 0 0 30px 0;
  color: #FFFFFF;
  &::before{
    content: '能耗统计';
    display: block;
    font-size: 22px;
    font-family: SourceHanSansCN;
    font-weight: 400;
    color: #FFFFFF;
    padding: 15px 0;
    padding-left: 60px;
    margin-bottom: 15px;
  }
  .todayEnergy{
    font-size: 16px;
    font-family: SourceHanSansCN;
    font-weight: 400;
    color: #FFFFFF;
    text-align: center;
    // margin-bottom: 10px;
    span{
      font-size: 18px;
      font-family: SourceHanSansCN;
      font-weight: 500;
      color: #FFFFFF;
      background: linear-gradient(0deg, #00BDEB 0%, #00FFFF 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent; 
      }
  }
  .pia{
    // flex: 1;
    .chart{
      width: 100%;
      height: 200px;
      background: url('~@/assets/lab/piabg.png') no-repeat center  20px;
      background-size:contain;
    }

  }
  .table{
    .el-row:first-child{
      .el-col-6{
        font-size: 20px;
        font-weight: 400;
        color: #FFFFFF;
        background: linear-gradient(180deg, #919191 49.4873046875%, #FFFFFF 52.001953125%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;     
       }
       .el-col-offset-1{
        background: linear-gradient(0deg, #00BDEB 0%, #00FFFF 100%);
        background: linear-gradient(0deg, #00BDEB 0%, #00FFFF 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 20px;
        font-weight: 400;
        color: #FFFFFF;
        }
    }
    .el-row{
      display: flex;
      align-items: center;
      font-family: SourceHanSansCN;
      margin-top: 10px;
      .el-col-10{
        font-size: 16px;
        font-weight: 400;
        color: #FFFFFF;
        text-align: right;      
      }
      .el-col-6{
        font-size: 28px;
        font-weight: 500;
        color: #999999; 
        span{
          font-size: 16px;
          color: #FFFFFF;
        }     
      }
      
    }
  }
}
}
</style>