<template>
  <div class="labIndex">
    <top />
    <mid />
    <bottom />
  </div>
</template>
<script>
import top from './components/top.vue'
import mid from './components/mid..vue'
import bottom from './components/bottom.vue'

export default{
  components:{top,mid,bottom},
  data(){
    return{

    }
  },
  created(){
  },
  methods:{
  
  }
}
</script>
<style lang="scss" scoped>
*{
  box-sizing: border-box;
}
.labIndex{
  width: 100%;
  height: 100vh;
  background: url('~@/assets/lab/bg.png') no-repeat center center;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  .mid{
   margin-top: 8px;
    // height: 576px;
  }
  .labBottom{
    margin-top: 20px;
  }
  
}
</style>