<template>
  <div class="labTop">
    <div class="time">
      <div class="top">
        {{  $moment(time).format("HH:mm") }}
      </div>
      <div class="bottom">
        {{ `${this.week}   ${this.date}` }}
      </div>
    </div>

  </div>
</template>
<script>
// var interval
export default {
  data () {
    return {
      time:this.$moment(),
      date:''
    }
  },
  created(){
    this.getTime()
    this.getweek()

  },
  mounted(){
    setInterval(() => {
      this.time = new Date()
    }, 10000);
    setInterval(() => {
      this.getTime()
      this.getweek()
    },43200000);
  },
  methods:{
    getTime(){
      this.date=this.$moment().format('YYYY-MM-DD')
    },
    getweek(){
      const weekday = this.$moment().day();
      switch (weekday) {
        case 1:
          this.week = "星期一";
          break;
        case 2:
          this.week = "星期二";
          break;
        case 3:
          this.week = "星期三";
          break;
        case 4:
          this.week = "星期四";
          break;
        case 5:
          this.week = "星期五";
          break;
        case 6:
          this.week = "星期六";
          break;
        case 0:
          this.week = "星期日";
          break;
      }
    }

  }
}

</script>
<style lang="scss" scoped>
*{
  box-sizing: border-box;
}
.labTop{
  width: 100%;
  height:100px;
  background: url('~@/assets/lab/labTop.png') no-repeat center center;
  background-size: 100% 100%;
  font-family: Source Han Sans CN;

.time{
  font-size: 20px;
font-weight: 400;
color: #FFFFFF;
line-height: 16px;
float: right;
padding: 20px;
.bottom{
  margin-top: 5px;
  font-size: 14px;
font-family: Source Han Sans CN;
font-weight: 400;
color: #FFFFFF;
line-height: 16px;
}
}

}
</style>